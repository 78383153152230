<template>
    <vue2ChatComponent />
</template>

<script>
import vue2ChatComponent from '@apps/vue2ChatComponent'
import pageMeta from '@/mixins/pageMeta'
export default {
    mixins: [pageMeta],
    components: {
        vue2ChatComponent
    }
}
</script>