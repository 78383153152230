<template>
    <component :is="chat" />
</template>
<script>
export default {
    computed: {
        isMobile() {
            return this.$store.state.isMobile
        },
        chat() {
            if(this.isMobile)
                return () => import('./ChatComponentMobile.vue')
            else
                return () => import('./ChatComponent.vue')
        }
    }
}
</script>